// src/components/FormContainer.js
import React, { useState } from "react";
import axios from "axios";
import StepProgressBar from "./StepProgressBar";
import StepBrand from "./StepBrand";
import StepDealership from "./StepDealership";
import StepModel from "./StepModel";
import StepNewOrUsed from "./StepNewOrUsed";
import StepContactDetails from "./StepContactDetails";

const initialFormData = {
  brand: "",
  dealership: "",
  model: "",
  newOrUsed: "Noua",
  firstName: "",
  lastName: "",
  phone: "",
  email: "",
  message: "",
  contactMethod: [],
  consent: false,
};

const FormContainer = () => {
  const [step, setStep] = useState(0);
  const [formData, setFormData] = useState(initialFormData);
  const [formSubmitted, setFormSubmitted] = useState(false);
  const [captchaToken, setCaptchaToken] = useState("");
  const [errors, setErrors] = useState({});
  const [loading, setLoading] = useState(false);

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setFormData({
      ...formData,
      [name]: type === "checkbox" ? checked : value,
    });
  };

  const handleContactMethodChange = (selectedOptions) => {
    setFormData({ ...formData, contactMethod: selectedOptions });
  };

  const handleCaptchaChange = (token) => {
    setCaptchaToken(token);
  };

  const validateFields = () => {
    const newErrors = {};
    if (!formData.firstName) newErrors.firstName = "First Name is required";
    if (!formData.lastName) newErrors.lastName = "Last Name is required";
    if (!formData.phone) newErrors.phone = "Phone Number is required";
    if (!formData.email) newErrors.email = "Email Address is required";
    if (formData.contactMethod.length === 0)
      newErrors.contactMethod = "Please select at least one contact method";
    if (!formData.consent)
      newErrors.consent = "You must agree to the data protection policy";
    if (!captchaToken) newErrors.captcha = "CAPTCHA is required";
    return newErrors;
  };

  const handleNext = (newData, final = false) => {
    setFormData((prev) => ({ ...prev, ...newData }));

    if (final) {
      handleSubmit();
      return;
    }

    setStep((prev) => prev + 1);
  };

  const handleSubmit = async (e) => {
    if (e) e.preventDefault();

    const newErrors = validateFields();
    if (Object.keys(newErrors).length > 0) {
      setErrors(newErrors);
      return;
    }

    setLoading(true);

    try {
      await axios.post("https://offerfest.pia.ro/api/send-email", { formData });

      setFormSubmitted(true);
      setFormData(initialFormData);
      setStep(5);  // Set step to 5 to indicate submission page
      setCaptchaToken("");
      setErrors({});
    } catch (error) {
      console.error("Form submission failed:", error);
      alert("Form submission failed. Please try again.");
    } finally {
      setLoading(false);
    }
  };

  const renderStep = () => {
    switch (step) {
      case 0:
        return <StepBrand formData={formData} handleNext={handleNext} />;
      case 1:
        return <StepDealership formData={formData} handleNext={handleNext} />;
      case 2:
        return <StepModel formData={formData} handleNext={handleNext} />;
      case 3:
        return <StepNewOrUsed formData={formData} handleNext={handleNext} />;
      case 4:
        return (
          <StepContactDetails
            formData={formData}
            handleInputChange={handleInputChange}
            handleContactMethodChange={handleContactMethodChange}
            handleCaptchaChange={handleCaptchaChange}
            handleNext={handleNext}
            errors={errors}
            loading={loading}
          />
        );
      default:
        return null;
    }
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-lg w-full max-w-lg">
      <StepProgressBar step={formSubmitted ? 5 : step} />
      {formSubmitted ? (
        <div className="text-center">
          <h2 className="text-xl font-bold">Formularul a fost trimis cu succes.</h2>
          <p>Va multumim! In cel mai scurt timp veti fi contactat de un coleg specialist vanzari.</p>
        </div>
      ) : (
        <form onSubmit={handleSubmit}>{renderStep()}</form>
      )}
    </div>
  );
};

export default FormContainer;
