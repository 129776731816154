// src/components/StepModel.js
import React from "react";
import { brands } from "../constants";

const StepModel = ({ formData, handleNext }) => (
  <div className="mb-4 text-center">
    <h1 className="text-xl pb-5">Alegeti modelul</h1>
    <div className="flex flex-wrap gap-4 justify-center">
      {brands
        .find((b) => b.name === formData.brand)
        .models.map((model) => (
          <button
            key={model}
            type="button"
            onClick={() => handleNext({ model })}
            className={`flex flex-col items-center justify-center w-36 h-24 bg-gray-100 rounded-lg hover:bg-gray-200 ${
              formData.model === model ? "ring-2 ring-blue-500" : ""
            }`}
          >
            <img
              src={`/models/${model.toLowerCase()}.png`}
              alt={model}
              className="w-24"
            />
            {model}
          </button>
        ))}
    </div>
  </div>
);

export default StepModel;
