// src/constants.js
export const brands = [
  {
    name: "Volkswagen",
    logo: "/logos/volkswagen.png",
    models: [
      "Polo",
      "Golf",
      "GolfFL",
      "Taigo",
      "Passat",
      "T-Cross",
      "T-Roc",
      "Tiguan",
      "Touareg",
      "ID3",
      "ID4",
      "ID5",
      "ID7",
    ],
  },
  {
    name: "Skoda",
    logo: "/logos/skoda.png",
    models: ["Fabia", "Scala", "Octavia", "Superb", "Karoq", "Kodiaq"],
  },
  {
    name: "Seat",
    logo: "/logos/seat.png",
    models: ["Ibiza", "Leon", "Ateca", "Tarraco"],
  },
  {
    name: "Seat",
    logo: "/logos/cupra.png",
    models: ["Leon", "Born", "Formentor"],
  },
  {
    name: "Audi",
    logo: "/logos/audi.png",
    models: ["A3", "A4", "Q2", "Q3", "Q4", "Q5", "Q7", "Q8"],
  },
];

export const dealerships = [
  {
    name: "Porsche Bucuresti Nord",
    address: "Bd. Pipera 2, Voluntari, Ilfov",
    brands: ["Volkswagen", "Skoda", "Seat", "Cupra"],
  },
  {
    name: "Porsche Bucuresti Vest 1",
    address: "Sos. de centura 41, Chiajna, Jud. Ilfov",
    brands: ["Volkswagen", "Skoda"],
  },
  {
    name: "Porsche Bucuresti Vest 2",
    address: "Sos. de centura 53, Chiajna, Jud. Ilfov",
    brands: ["Seat", "Audi"],
  },
  {
    name: "Porsche Pipera",
    address: "Bd. Pipera 1/X, Voluntari, Ilfov",
    brands: ["Audi"],
  },
];

export const contactOptions = [
  { value: "Telefon", label: "Telefon" },
  { value: "Email", label: "Email" },
  { value: "WhatsApp", label: "WhatsApp" },
];
