import React from "react";
import FormContainer from "./components/FormContainer";
import ConsentDialog from "./components/ConsentDialog";
import "./index.css";

const App = () => (
  <div className="min-h-screen bg-[#384967] flex flex-col items-center justify-center p-2">
    <a href="/">
      <img
        src="/logos/pialogo.png"
        className="w-24"
        alt="Porsche Inter Auto Logo"
      />
    </a>
    <h1 className="text-3xl p-5 text-white">Porsche Inter Auto</h1>
    <FormContainer />
    <div className="mt-10 mb-3">
      <span className="text-xs text-white">
        &copy; Porsche Inter Auto Romania - Toate drepturile rezervate.
      </span>
    </div>
    <ConsentDialog />
  </div>
);

export default App;
