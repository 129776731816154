// src/components/StepProgressBar.js
import React from "react";

const StepProgressBar = ({ step }) => (
  <div className="w-full bg-gray-200 rounded-full h-2.5 mb-4 overflow-hidden">
    <div
      className="bg-green-500 h-2.5 rounded-full transition-all duration-500"
      style={{ width: `${step === 5 ? 100 : (step / 4) * 100}%` }} // Set width to 100% if step is 5 (submission page)
    ></div>
  </div>
);

export default StepProgressBar;
