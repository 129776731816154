// src/components/StepNewOrUsed.js
import React from "react";

const StepNewOrUsed = ({ formData, handleNext }) => (
  <div className="mb-4 text-center">
    <h1 className="text-xl pb-5">Noua sau Rulata?</h1>
    <div className="flex flex-wrap gap-4 justify-center">
      <button
        type="button"
        onClick={() => handleNext({ newOrUsed: "Noua" })}
        className={`flex items-center justify-center w-24 h-24 bg-gray-100 rounded-lg hover:bg-gray-200 ${
          formData.newOrUsed === "Noua" ? "ring-2 ring-blue-500" : ""
        }`}
      >
        Noua
      </button>
      <button
        type="button"
        onClick={() => handleNext({ newOrUsed: "Rulata" })}
        className={`flex items-center justify-center w-24 h-24 bg-gray-100 rounded-lg hover:bg-gray-200 ${
          formData.newOrUsed === "Rulata" ? "ring-2 ring-blue-500" : ""
        }`}
      >
        Rulata
      </button>
    </div>
  </div>
);

export default StepNewOrUsed;
