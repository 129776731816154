// src/components/StepContactDetails.js
import React from "react";
import Select from "react-select";
import ReCAPTCHA from "react-google-recaptcha";
import { contactOptions } from "../constants";

const StepContactDetails = ({
  formData,
  handleInputChange,
  handleContactMethodChange,
  handleCaptchaChange,
  handleNext,
  errors,
  loading, // Receive loading state as a prop
}) => (
  <div className="mb-4 text-center">
    <h1 className="text-xl pb-5">Date de contactare</h1>
    <div className="flex flex-col gap-4 items-center">
      <input
        type="text"
        name="firstName"
        placeholder="First Name"
        value={formData.firstName}
        onChange={handleInputChange}
        required
        className="p-2 border rounded w-full md:w-80"
      />
      <input
        type="text"
        name="lastName"
        placeholder="Last Name"
        value={formData.lastName}
        onChange={handleInputChange}
        required
        className="p-2 border rounded w-full md:w-80"
      />
      <input
        type="tel"
        name="phone"
        placeholder="Phone Number"
        value={formData.phone}
        onChange={handleInputChange}
        required
        pattern="\d{10}"
        title="Phone number must be 10 digits"
        className="p-2 border rounded w-full md:w-80"
      />
      <input
        type="email"
        name="email"
        placeholder="Email Address"
        value={formData.email}
        onChange={handleInputChange}
        required
        className="p-2 border rounded w-full md:w-80"
      />
      <textarea
        name="message"
        placeholder="Message"
        value={formData.message}
        onChange={handleInputChange}
        className="p-2 border rounded w-full md:w-80"
      />
    </div>
    <div className="mt-4 w-full md:w-80 mx-auto">
      <h2 className="text-left">Cum doriti sa fiti contactat?</h2>
      <Select
        isMulti
        name="contactMethod"
        options={contactOptions}
        className="basic-multi-select"
        classNamePrefix="select"
        value={formData.contactMethod}
        onChange={handleContactMethodChange}
      />
    </div>
    <div className="mt-4 w-full md:w-80 mx-auto">
      <label className="flex items-center">
        <input
          type="checkbox"
          name="consent"
          checked={formData.consent}
          onChange={handleInputChange}
          className="form-checkbox"
        />
        <span className="ml-2 text-left">
          Am citit Pagina de Protectia Datelor si odata cu solicitarea mea, am
          luat la cunostinta prevederile acesteia.
        </span>
      </label>
      <ReCAPTCHA
        sitekey="6LcumfYpAAAAAKME8FiF5RVWxK5N9MiFnRYC4tmJ"
        onChange={handleCaptchaChange}
      />
      {errors.captcha && <div className="text-red-500">{errors.captcha}</div>}
    </div>
    <div className="mt-4">
      <button
        type="button"
        onClick={() => handleNext({}, true)}
        className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
        disabled={loading} // Disable button when loading
      >
        {loading ? (
          <svg
            className="animate-spin h-5 w-5 text-white"
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
          >
            <circle
              className="opacity-25"
              cx="12"
              cy="12"
              r="10"
              stroke="currentColor"
              strokeWidth="4"
            ></circle>
            <path
              className="opacity-75"
              fill="currentColor"
              d="M4 12a8 8 0 018-8V0C5.373 0 0 5.373 0 12h4zm2 5.291A7.962 7.962 0 014 12H0c0 2.21.896 4.21 2.343 5.657l1.657-1.366z"
            ></path>
          </svg>
        ) : (
          "Trimite"
        )}
      </button>
    </div>
  </div>
);

export default StepContactDetails;
